<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>招生咨询</a-breadcrumb-item>
      <a-breadcrumb-item>我的合同</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-box">
      <div class="clearfix table-tools">
        <div class="buttons">
          <a-form layout='inline'>
            <a-form-item v-if="canExcelexport">
              <!-- <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button> -->
            </a-form-item>
          </a-form>
        </div>
        <div class="search">
          <a-form layout='inline' @submit="searchList">
            <a-form-item>
                <a-tooltip placement="topLeft" >
                <a-select :value='searchData.is_sub' @change='handleIsSub' style="width: 100px">
                    <a-select-option :value="0">我的</a-select-option>
                    <a-select-option :value="1">我下属的</a-select-option>
                </a-select>
                </a-tooltip>
            </a-form-item>
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>学员名称/手机号</span>
                </template>
                <a-input v-model.trim="searchData.fuzzy" placeholder="学员名称/手机号" style="width: 140px"/>
              </a-tooltip>
            </a-form-item>
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>签约合同号</span>
                </template>
                <a-input v-model.trim="searchData.contract_no" placeholder="签约合同号" style="width: 140px"/>
              </a-tooltip>
            </a-form-item>
            <a-form-item v-if="canIndex">
              <a-button type="primary" html-type="submit" :loading="loading" @click="searchList">搜索</a-button>
            </a-form-item>
            <a-form-item>
              <a-popover placement="bottom" trigger="click" v-model="visible">
                <template slot="content">
                  <div class="more-search">
                    <a-form layout='inline'>
                      <a-form-item class="block-line" label="签约校区" >
                        <a-select @change="changeStudio" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' v-model="searchData.studio_id" placeholder="请选择">
                          <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <!-- <a-form-item class="block-line" label="课程收费" >
                        <a-select v-model="searchData.studio_id" placeholder="请选择">
                          <a-select-option v-for="(d, index) of courseFees" :key="index" :value="d['fee_id']">{{ d['fee_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item> -->
                      <a-form-item class="block-line" label="课程科目" >
                        <a-select v-model="searchData.course_cate_id" placeholder="请选择">
                          <a-select-option v-for="(d, index) of categorys" :key="index" :value="d['course_cate_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item class="block-line" label="合同类型" >
                        <a-select v-model="searchData.contract_type" placeholder="请选择">
                          <a-select-option v-for="(d, index) of contractTypes" :key="index" :value="d['contract_type']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item class="block-line" label="添加时间" >
                        <a-range-picker :ranges="rangesData" :disabled-date="disabledDate" :allowClear="false" v-model="searchData.created_at" @change="handleTime" :placeholder="['开始时间', '结束时间']"/>
                      </a-form-item>
                      <!-- <a-form-item class="block-line" label="课程顾问" > 
                        <a-select v-model="searchData.course_consultant_id" placeholder="请选择">
                          <a-select-option v-for="(d, index) of consultants" :key="index" :value="d['course_consultant_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item> -->
                      <!-- <a-form-item label="经办人" >
                        <a-select showSearch placeholder="请选择">
                          <a-select-option value="jack">Jack</a-select-option>
                          <a-select-option value="lucy">Lucy</a-select-option>
                          <a-select-option value="Yiminghe">yiminghe</a-select-option>
                        </a-select>
                      </a-form-item> -->
                    </a-form>
                    <div class="more-search__buttons">
                      <a-button type="dashed" @click="closeSearch">取消</a-button>
                      <a-button @click="reset">重置</a-button>
                      <a-button v-if="canIndex" type="primary" @click="searchList">搜索</a-button>
                    </div>
                  </div>
                </template>
                <a-button>更多<a-icon type="down" /></a-button>
              </a-popover>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="middle" :pagination="false" :bordered='false' rowKey="contract_id"
          :columns="columns" :dataSource="list" :scroll="{ x: 1700 }" @change="handleChange">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <template slot="studentName" slot-scope="text, record">
            <LUser :data="record"/>
          </template>
          <template slot="pdf" slot-scope="text, record">
            <a-icon @click="toPreviewPDF(record)" v-show="record.contract_pdf" type="file-pdf" style="font-size: 18px;vertical-align: middle;cursor: pointer;"  />
          </template>
          <template slot="out_trade_no" slot-scope="text,record">
            <a href="javascript:;" @click="showOrderModal(record)">{{ text }}</a>
          </template>
        </a-table>
      </div>
      <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
    </div>
    <orderModal v-if="orderVisible && canRead" :isFinance='0' :item="modalData"/>
    <contractPdf v-if="isContractPdf" :pdfPath="pdfPath" />
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '学员名称', width: '160px', dataIndex: 'student_name', key: 'name', fixed: 'left', scopedSlots: { customRender: 'studentName' } },
  { title: '', width:'40px', scopedSlots: { customRender: 'pdf' }, key: 'contract_pdf', fixed: 'left',align:'center'},
  { title: '手机号码', width: '110px', dataIndex: 'student_cellphone', key: 'student_cellphone' ,fixed: 'left'},
  { title: '签约合同号', width: '160px', dataIndex: 'contract_no', key: 'contract_no', scopedSlots: { customRender: 'out_trade_no' } ,fixed: 'left'},
  { title: '课程顾问', dataIndex: 'course_consultant', key: 'course_consultant',align:'center',},
  { title: '签约校区', dataIndex: 'studio_id', key: '3' ,align:'center',ellipsis: true},
  { title: '课程科目', dataIndex: 'course_cate_id', key: '5' },
  { title: '课程花费', dataIndex: 'contract_costs', key: '7' ,align:'center',sorter:()=>{}},
  { title: '优惠金额', dataIndex: 'contract_discount', key: '8' ,align:'center',sorter:()=>{}},
  { title: '签约课时', dataIndex: 'times', key: '10',align:'center' ,sorter:()=>{}},
  { title: '赠送课时', dataIndex: 'gift_times', key: '11' ,align:'center',sorter:()=>{}},
  // { title: '可请假课时', dataIndex: 'cancel_times', key: '12' },
  { title: '有效期至', dataIndex: 'contract_end_date', key: 'contract_end_date' ,align:'center',sorter:()=>{}},
  { title: '合同类型', dataIndex: 'contract_type', key: '13' ,align:'center'},
  { title: '合同状态', dataIndex: 'contract_status', key: 'contract_status',align:'center' },
  { title: '经办人', dataIndex: 'created_by', key: '14' },
  { title: '操作时间', dataIndex: 'created_at', key: '15' ,sorter:()=>{}}
]

import C_ITEMS from '@/utils/items'
import orderModal from '@/views/finance/daily/orderModal'
import ranges from "@/common/mixins/ranges"
import authority from '@/common/mixins/authority'
import moment from 'moment'
import contractPdf from './contractPdf'

export default {
  name: 'ClientResourceContract',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    orderModal,
    contractPdf,
  },

  data() {
    return {
      loading: false,
      exportLoading:false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      paymentStatus: C_ITEMS.paymentStatus,
      studios: [],
      courseFees: [],
      categorys: [],
      contractTypes: [],
      consultants: [],

      isContractPdf:false,
      pdfPath:'',

      searchData: {
        is_sub:0,
      },

      visible: false,
      refundVisible: false,
      orderVisible: false,
      authType:['crm','contract']
    }
  },
  mixins: [ ranges , authority ],
  beforeCreate () {
      if(!this.$ls.get('is_sub')){
          this.$ls.set('is_sub', 0)
      }
  },
  created() {
    this.pageParams.perPage = this.$ls.get('perPage') || 10
    this.searchParams['per-page'] = this.pageParams.perPage
    if(this.$route.query.start_date){
      this.searchData.created_at = [moment(this.$route.query.start_date),moment(this.$route.query.end_date)]
    }
    this.getList()
    this.getStudio()
    this.getClassCategory()
    this.getContractType()
  },

  methods: {
    closeSearch() {
      this.visible = false
    },
    async toPreviewPDF(item){
        this.isContractPdf=true;
        this.pdfPath = item.contract_pdf;      
        // console.log(this.isContractPdf,this.pdfPath)
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchStudioAction', {})
      this.studios = res.data
    },
    async changeStudio(studio) {
      let res = await this.$store.dispatch('searchCourseFeeAction', { data: {studio_id: studio} })
      this.courseFees = res.data
    },
    async getClassCategory() {
        let res = await this.$store.dispatch('searchCourseTypeAction', {})
        this.categorys = res.data
    },
    async getContractType() {
      let res = await this.$store.dispatch('searchContractTypeAction', {})
      this.contractTypes = res.data
    },

    async getList() {
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_at') {
            obj['start_date'] = moment(searchData[k][0]).format("YYYY-MM-DD")
            obj['end_date'] = moment(searchData[k][1]).format("YYYY-MM-DD")
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      this.searchParams.search.is_sub = this.$ls.get('is_sub')
      this.searchData.is_sub = this.$ls.get('is_sub')
      let res = await this.$store.dispatch('cContractAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    changePage(page, pageSize) {
      this.searchParams.page = page
      this.getList()
    },
    onShowSizeChange(current, size) {
      this.searchParams['per-page'] = size
      this.$ls.set('perPage',size)
      this.getList()
    },
    reset() {
      this.searchData = {}
    },
    searchList(e) {
      e?e.preventDefault():''
      this.searchParams.page = 1
      this.getList()
    },

    showRefundModal(item) {
      this.modalData = item
      this.refundVisible = true
    },
    hideRefundModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.refundVisible = false
    },
    handleChange(pagination, filters, sorter){
        if(sorter.order){
            if(sorter.order === "ascend"){
                this.searchParams['sort'] = `${sorter.field}`
            }else{
                this.searchParams['sort'] = `-${sorter.field}`
            }
            }else{
            this.searchParams['sort'] = ''
            }
        this.getList();
    },
    showOrderModal(item) {
      this.modalData = item
      this.orderVisible = true
    },
    handleIsSub(e){
        this.$ls.set('is_sub', e)
        this.searchData.is_sub = e
    },
    hideOrderModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.orderVisible = false
    },
    handleTime(){
      this.$forceUpdate()
    },
    async toExport(){
            this.exportLoading = true
            let exportData = {
                search:this.searchData.search,
                sort:this.searchParams.sort
            }
            let res = await this.$store.dispatch('cContractExportAction', exportData)
            const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
            if ('download' in document.createElement('a')) { 
                const link = document.createElement('a')
                link.download = '合同.xlsx'
                link.style.display = 'none'
                link.href = URL.createObjectURL(blob)
                document.body.appendChild(link)
                link.click()
                URL.revokeObjectURL(link.href) 
                document.body.removeChild(link)
            } else { //其他浏览器
                navigator.msSaveBlob(blob, fileName)
            }
            this.exportLoading = false
    }
  }
}
</script>